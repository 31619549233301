import { AttachmentIcon } from "@chakra-ui/icons";
import { Box, IconButton, useToast } from "@chakra-ui/react";
import type { BoxProps } from "@chakra-ui/react";

import { useCallback, useState } from "react";
import type { PropsWithChildren } from "react";
import { Constants } from "../../../constants";

type StorageKeyType =
  | typeof Constants.FAVORITE_JOBS_STORAGE_KEY
  | typeof Constants.FAVORITE_USERS_STORAGE_KEY;

type Props<T> = {
  item: T;
  display: BoxProps["display"];
  keyName: StorageKeyType;
};

const getCurrentStateInColor = <T extends { id: string }>(
  id: string,
  keyName: StorageKeyType
) => {
  const currentFavorites: T[] = JSON.parse(
    localStorage.getItem(keyName) || "[]"
  );

  const allIds = currentFavorites.map((item) => item.id);

  if (allIds.includes(id)) {
    return "yellow";
  }

  return "gray";
};

const AddToFavoriteButton = <T extends { id: string }>({
  item,
  display,
  keyName,
}: PropsWithChildren<Props<T>>) => {
  const [color, setColor] = useState(
    getCurrentStateInColor<T>(item.id, keyName)
  );
  const toast = useToast();

  const handleFavorite = useCallback(() => {
    const currentFavorites: T[] = JSON.parse(
      localStorage.getItem(keyName) || "[]"
    );

    const allJobs = currentFavorites.map((item) => item.id);
    const hasItem = allJobs.includes(item.id);

    // remove from favorite
    if (hasItem) {
      const removedCurrent = currentFavorites.filter(
        (currentJob) => currentJob.id !== item.id
      );
      localStorage.setItem(keyName, JSON.stringify(removedCurrent));
      setColor("gray");
      toast({
        title: "Removed from favorite",
        status: "info",
        isClosable: true,
      });
    }

    // add to favorite
    if (!hasItem) {
      currentFavorites.push(item);
      localStorage.setItem(keyName, JSON.stringify(currentFavorites));
      setColor("yellow");
      toast({
        title: "Add to favorite",
        status: "success",
        isClosable: true,
      });
    }
  }, [item, keyName, toast]);

  return (
    <Box display={display}>
      <IconButton
        aria-label="Add to favorite"
        colorScheme={color}
        icon={<AttachmentIcon />}
        onClick={handleFavorite}
        fontWeight="bold"
      />
    </Box>
  );
};

export { AddToFavoriteButton };
