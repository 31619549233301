import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import dynamic from "next/dynamic";
import Head from "next/head";
import type { ReactElement } from "react";
import { useState } from "react";
import { Constants } from "../constants";
import type { NextPageWithLayout } from "./_app";
import { SearchContext } from "@common/client/store";
import type { LabelType } from "@common/client/store";
import { SEOLayout } from "@components/SEOLayout";
import { JobHunterTab } from "@components/pages/index/JobHunterTab";
import { RecruiterTab } from "@components/pages/index/RecruiterTab";

const Home: NextPageWithLayout = () => {
  const [searchInput, setSearchInput] = useState<LabelType[]>([]);
  const resource = {
    searchInput,
    setSearchInput,
  };

  return (
    <>
      <Head>
        <title>{Constants.APP_NAME}</title>
      </Head>
      <SearchContext.Provider value={resource}>
        <Tabs isLazy mt="24">
          <TabList>
            <Tab
              _selected={{ color: "white", bg: "gray.900", fontSize: "3xl" }}
              fontWeight="bold"
              fontSize="xl"
            >
              I am a Recruiter&nbsp;&#x1f440;
            </Tab>
            <Tab
              _selected={{ color: "white", bg: "gray.900", fontSize: "3xl" }}
              fontWeight="bold"
              fontSize="xl"
            >
              I am a Job Hunter&nbsp;&#x1f98c;
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel p="0">
              <RecruiterTab />
            </TabPanel>
            <TabPanel p="0">
              <JobHunterTab />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </SearchContext.Provider>
    </>
  );
};

Home.getLayout = (page: ReactElement) => {
  const host = process.env.NEXT_PUBLIC_HOST_URL as string;
  return (
    <SEOLayout description={Constants.APP_NAME} image={`${host}/logo.svg`}>
      {page}
    </SEOLayout>
  );
};

export default Home;
