import { getSkillImage } from "./skillImage";
import { FormType } from "@pages/jobs/new";

const KEYS_FOR_PREV_JOB = [
  "zipCode",
  "country",
  "state",
  "street",
  "building",
  "name",
  "websiteUrl",
  "email",
  "description",
  "title",
  "category",
  "skills",
  "jobType",
  "applicationContact",
  "jobDescription",
  "logo",
  "language",
];

const getPrevJobs = (): FormType => {
  const value: any = {};
  if (typeof window === "undefined") return value;
  KEYS_FOR_PREV_JOB.forEach((key) => {
    value[key] = sessionStorage.getItem(key);
  });

  return value;
};

const getAllFavorites = <T = any>(key: string): T => {
  return JSON.parse(localStorage.getItem(key) || "[]");
};

const getRandomAnimal = () => {
  const animals = ["sheep", "fox", "owl", "frog"];
  return animals[Math.floor(Math.random() * animals.length)];
};

export {
  KEYS_FOR_PREV_JOB,
  getPrevJobs,
  getAllFavorites,
  getRandomAnimal,
  getSkillImage,
};
