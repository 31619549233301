import { useQuery } from "@apollo/client";
import { VStack, Box } from "@chakra-ui/react";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { AllJobsDocument } from "../../../generated/types";
import { JobLink } from "./JobLink";
import type { AllJobsQuery } from "@codegen";

const AllJobs = () => {
  const [hasMore, setHasMore] = useState(true);
  const [jobs, setJobs] = useState<AllJobsQuery["allJobs"]>([]);
  const FETCH_LIMIT = 5;
  const { fetchMore } = useQuery(AllJobsDocument, {
    onCompleted: (data) => {
      const jobLen = data.allJobs.length;
      (!jobLen || jobLen < FETCH_LIMIT) && setHasMore(false);
      setJobs((prev) => [...prev, ...data.allJobs]);
    },
  });

  const loadFunc = async () => {
    if (!jobs.length) return;
    const { data } = await fetchMore({
      variables: { id: jobs[jobs.length - 1].id },
    });

    const jobLen = data.allJobs.length;
    (!jobLen || jobLen < FETCH_LIMIT) && setHasMore(false);
    setJobs((prev) => [...prev, ...data.allJobs]);
  };
  return (
    <Box width="full">
      <InfiniteScroll
        // pageStart={0}
        loadMore={loadFunc}
        hasMore={hasMore}
        // initialLoad={false}
        loader={
          <div className="loader" key={0}>
            Loading ...
          </div>
        }
      >
        <VStack width="full" spacing="6">
          {jobs.map((job) => (
            <JobLink key={job.id + "all"} job={job} />
          ))}
        </VStack>
      </InfiniteScroll>
    </Box>
  );
};

export { AllJobs };
