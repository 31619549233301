import { useQuery } from "@apollo/client";
import { Box, VStack } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { UserLink } from "./UserLink";
import { SearchUsersDocument } from "@codegen";
import type { SearchUsersQuery } from "@codegen";
import { SearchContext } from "@common/client/store";

const FilteredUsers = () => {
  const { searchInput } = useContext(SearchContext);

  const [hasMore, setHasMore] = useState(true);
  const [users, setUsers] = useState<SearchUsersQuery["searchUsers"]>([]);
  const FETCH_LIMIT = 5;
  const { fetchMore } = useQuery(SearchUsersDocument, {
    variables: {
      query: searchInput.map((item) => item.value),
    },
    onCompleted: (data) => {
      const jobLen = data.searchUsers.length;
      (!jobLen || jobLen < FETCH_LIMIT) && setHasMore(false);
      setUsers((prev) => [...prev, ...data.searchUsers]);
    },
  });

  useEffect(() => {
    setUsers([]);
  }, [searchInput]);

  const loadFunc = async () => {
    const query = searchInput.map((item) => {
      return item.value;
    });
    if (!query.length) return;
    if (!users.length) return;

    const id = users.length ? users[users.length - 1].id : null;
    const variables = {
      query,
      id,
    };

    const { data } = await fetchMore({
      variables,
    });

    const jobLen = data.searchUsers.length;
    (!jobLen || jobLen < FETCH_LIMIT) && setHasMore(false);
    setUsers((prev) => [...prev, ...data.searchUsers]);
  };
  // todo:  look up from resource
  return (
    <Box width="full">
      <InfiniteScroll
        loadMore={loadFunc}
        hasMore={hasMore}
        loader={
          <div className="loader" key={0}>
            Loading ...
          </div>
        }
      >
        <VStack width="full" spacing="6">
          {users.map((user) => (
            <UserLink key={user.id + "filtered"} user={user} />
          ))}
        </VStack>
      </InfiniteScroll>
    </Box>
  );
};

export { FilteredUsers };
