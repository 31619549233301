import { VStack, Box, Badge, Flex } from "@chakra-ui/react";

import NextImage from "next/image";
import { FC } from "react";
import type { AllUsersQuery } from "@codegen";
import { getSkillImage } from "@common/client";

const UserSkillBadge: FC<{ user: AllUsersQuery["allUsers"][number] }> = ({
  user,
}) => {
  const fontSize = { base: "xs", md: "md" };
  const { profile } = user;

  return (
    <Box flex="1">
      <VStack>
        <Flex width="full" gridGap="2" flexWrap="wrap">
          <Badge colorScheme="red" variant="outline" fontSize={fontSize}>
            {profile?.jobType}
          </Badge>

          {profile?.categories.map((category) => (
            <Badge
              key={category.id}
              colorScheme="red"
              variant="outline"
              fontSize={fontSize}
            >
              {category.category}
            </Badge>
          ))}
        </Flex>
        <Flex width="full" gridGap="2" flexWrap="wrap">
          {profile?.skills.map((skill) => {
            return (
              <NextImage
                src={getSkillImage(skill.skill)}
                key={skill.id}
                alt={skill.skill}
                width={32}
                height={32}
              />
            );
          })}
        </Flex>
      </VStack>
    </Box>
  );
};

export { UserSkillBadge };
