import { useQuery } from "@apollo/client";
import { VStack, Box } from "@chakra-ui/react";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { AllUsersDocument } from "../../../generated/types";
import { UserLink } from "./UserLink";
import type { AllUsersQuery } from "@codegen";

const AllUsers = () => {
  const [hasMore, setHasMore] = useState(true);
  const [users, setUsers] = useState<AllUsersQuery["allUsers"]>([]);
  const FETCH_LIMIT = 5;
  const { fetchMore } = useQuery(AllUsersDocument, {
    onCompleted: (data) => {
      const jobLen = data.allUsers.length;
      (!jobLen || jobLen < FETCH_LIMIT) && setHasMore(false);
      setUsers((prev) => [...prev, ...data.allUsers]);
    },
  });

  const loadFunc = async () => {
    if (!users.length) return;
    const { data } = await fetchMore({
      variables: { id: users[users.length - 1].id },
    });
    const jobLen = data.allUsers.length;
    (!jobLen || jobLen < FETCH_LIMIT) && setHasMore(false);
    setUsers((prev) => [...prev, ...data.allUsers]);
  };
  return (
    <Box width="full">
      <InfiniteScroll
        loadMore={loadFunc}
        hasMore={hasMore}
        loader={
          <div className="loader" key={0}>
            Loading ...
          </div>
        }
      >
        <VStack width="full" spacing="6" id="all-users">
          {users.map((user) => (
            <UserLink key={user.id + "all"} user={user} />
          ))}
        </VStack>
      </InfiniteScroll>
    </Box>
  );
};

export { AllUsers };
