import { HStack, Switch, VStack } from "@chakra-ui/react";
import dynamic from "next/dynamic";
import { useContext, useState } from "react";
import type { ChangeEvent, FC } from "react";
import { AllJobs } from "./AllJobs";
import { FilteredJobs } from "./FilteredJobs";
import { SearchContext } from "@common/client/store";

const SearchForm = dynamic(
  () => import("./SearchForm").then((mod) => mod.SearchForm),
  { ssr: false }
);

const FavoriteJobs = dynamic(
  () =>
    import("@components/pages/index/FavoriteJobs").then(
      (mod) => mod.FavoriteJobs
    ),
  { ssr: false }
);

const JobHunterTab = () => {
  const { searchInput } = useContext(SearchContext);

  const [lookingFavorite, setLookingFavorite] = useState<boolean>(false);

  const handleSwitch = (event: ChangeEvent<HTMLInputElement>): void => {
    const isChecked = event.currentTarget.checked;
    setLookingFavorite(isChecked);
  };

  return (
    <VStack width="full" alignItems="flex-start" mt="6" spacing="8">
      {/* <Text fontWeight="bold" fontSize="3xl">
      All Remote Jobs &#x1f4bc;
    </Text> */}
      <HStack
        width="full"
        spacing="6"
        justifyContent="center"
        alignItems="center"
      >
        <Switch
          variant="clipIcon"
          height="full"
          colorScheme="red"
          size="lg"
          onChange={handleSwitch}
        />
        <SearchForm isDisabled={lookingFavorite} />
      </HStack>
      {lookingFavorite && <FavoriteJobs />}
      {!lookingFavorite && !!searchInput.length && <FilteredJobs />}
      {!lookingFavorite && !searchInput.length && <AllJobs />}
    </VStack>
  );
};

export { JobHunterTab };
