import { useQuery } from "@apollo/client";
import { Box, VStack } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { JobLink } from "./JobLink";
import { SearchJobsDocument, SearchJobsQuery } from "@codegen";
import { SearchContext } from "@common/client/store";

const FilteredJobs = () => {
  const { searchInput } = useContext(SearchContext);

  const [hasMore, setHasMore] = useState(true);
  const [jobs, setJobs] = useState<SearchJobsQuery["searchJobs"]>([]);
  const FETCH_LIMIT = 5;
  const { fetchMore } = useQuery(SearchJobsDocument, {
    variables: {
      query: searchInput.map((item) => item.value),
    },
    onCompleted: (data) => {
      const jobLen = data.searchJobs.length;
      (!jobLen || jobLen < FETCH_LIMIT) && setHasMore(false);
      setJobs((prev) => [...prev, ...data.searchJobs]);
    },
  });

  useEffect(() => {
    setJobs([]);
  }, [searchInput]);

  const loadFunc = async () => {
    const query = searchInput.map((item) => {
      return item.value;
    });
    if (!query.length) return;
    if (!jobs.length) return;

    const id = jobs.length ? jobs[jobs.length - 1].id : null;
    const variables = {
      query,
      id,
    };

    const { data } = await fetchMore({
      variables,
    });

    const jobLen = data.searchJobs.length;
    (!jobLen || jobLen < FETCH_LIMIT) && setHasMore(false);
    setJobs((prev) => [...prev, ...data.searchJobs]);
  };
  // todo:  look up from resource
  return (
    <Box width="full">
      <InfiniteScroll
        loadMore={loadFunc}
        hasMore={hasMore}
        // initialLoad={false}
        loader={
          <div className="loader" key={0}>
            Loading ...
          </div>
        }
      >
        <VStack width="full" spacing="6">
          {jobs.map((job) => (
            <JobLink key={job.id + "filtered"} job={job} />
          ))}
        </VStack>
      </InfiniteScroll>
    </Box>
  );
};

export { FilteredJobs };
