import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: string;
};

export type Address = {
  __typename?: 'Address';
  building?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  id: Scalars['ID'];
  state: Scalars['String'];
  street: Scalars['String'];
  zipCode: Scalars['String'];
};

export type AddressInput = {
  building: Scalars['String'];
  country: Country;
  state: Scalars['String'];
  street: Scalars['String'];
  zipCode: Scalars['String'];
};

/** Job Category */
export const Category = {
  BackendEngineer: 'BACKEND_ENGINEER',
  CustomerSupport: 'CUSTOMER_SUPPORT',
  Designer: 'DESIGNER',
  Devops: 'DEVOPS',
  FrontendEngineer: 'FRONTEND_ENGINEER',
  FullstackEngineer: 'FULLSTACK_ENGINEER',
  Management: 'MANAGEMENT',
  Other: 'OTHER',
  Qaengineer: 'QAENGINEER',
  Sales: 'SALES'
} as const;

export type Category = typeof Category[keyof typeof Category];
export type Company = {
  __typename?: 'Company';
  companyAddress?: Maybe<CompanyAddress>;
  description: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['ID'];
  jobs: Array<Maybe<Job>>;
  logo: Scalars['String'];
  name: Scalars['String'];
  websiteUrl: Scalars['String'];
};

export type CompanyAddress = {
  __typename?: 'CompanyAddress';
  address: Address;
  addressId: Scalars['String'];
  company?: Maybe<Company>;
  companyId: Scalars['String'];
  id: Scalars['ID'];
};

export type CompanyInput = {
  description: Scalars['String'];
  email: Scalars['String'];
  logo: Scalars['String'];
  name: Scalars['String'];
  websiteUrl: Scalars['String'];
};

/** all countries */
export const Country = {
  Afghanistan: 'AFGHANISTAN',
  Albania: 'ALBANIA',
  Algeria: 'ALGERIA',
  Andorra: 'ANDORRA',
  Angola: 'ANGOLA',
  AntiguaAndBarbuda: 'ANTIGUA_AND_BARBUDA',
  Argentina: 'ARGENTINA',
  Armenia: 'ARMENIA',
  Australia: 'AUSTRALIA',
  Austria: 'AUSTRIA',
  Azerbaijan: 'AZERBAIJAN',
  Bahamas: 'BAHAMAS',
  Bahrain: 'BAHRAIN',
  Bangladesh: 'BANGLADESH',
  Barbados: 'BARBADOS',
  Belarus: 'BELARUS',
  Belgium: 'BELGIUM',
  Belize: 'BELIZE',
  Benin: 'BENIN',
  Bhutan: 'BHUTAN',
  Bolivia: 'BOLIVIA',
  BosniaAndHerzegovina: 'BOSNIA_AND_HERZEGOVINA',
  Botswana: 'BOTSWANA',
  Brazil: 'BRAZIL',
  Brunei: 'BRUNEI',
  Bulgaria: 'BULGARIA',
  BurkinaFaso: 'BURKINA_FASO',
  Burundi: 'BURUNDI',
  CaboVerde: 'CABO_VERDE',
  Cambodia: 'CAMBODIA',
  Cameroon: 'CAMEROON',
  Canada: 'CANADA',
  CentralAfricanRepublic: 'CENTRAL_AFRICAN_REPUBLIC',
  Chad: 'CHAD',
  Chile: 'CHILE',
  China: 'CHINA',
  Colombia: 'COLOMBIA',
  Comoros: 'COMOROS',
  Congo: 'CONGO',
  CostaRica: 'COSTA_RICA',
  CoteDIvoire: 'COTE_D_IVOIRE',
  Croatia: 'CROATIA',
  Cuba: 'CUBA',
  Cyprus: 'CYPRUS',
  Czechia: 'CZECHIA',
  DemocraticRepublicOfTheCongo: 'DEMOCRATIC_REPUBLIC_OF_THE_CONGO',
  Denmark: 'DENMARK',
  Djibouti: 'DJIBOUTI',
  Dominica: 'DOMINICA',
  DominicanRepublic: 'DOMINICAN_REPUBLIC',
  Ecuador: 'ECUADOR',
  Egypt: 'EGYPT',
  ElSalvador: 'EL_SALVADOR',
  EquatorialGuinea: 'EQUATORIAL_GUINEA',
  Eritrea: 'ERITREA',
  Estonia: 'ESTONIA',
  Eswatini: 'ESWATINI',
  Ethiopia: 'ETHIOPIA',
  Fiji: 'FIJI',
  Finland: 'FINLAND',
  France: 'FRANCE',
  Gabon: 'GABON',
  Gambia: 'GAMBIA',
  Georgia: 'GEORGIA',
  Germany: 'GERMANY',
  Ghana: 'GHANA',
  Greece: 'GREECE',
  Grenada: 'GRENADA',
  Guatemala: 'GUATEMALA',
  Guinea: 'GUINEA',
  GuineaBissau: 'GUINEA_BISSAU',
  Guyana: 'GUYANA',
  Haiti: 'HAITI',
  HolySee: 'HOLY_SEE',
  Honduras: 'HONDURAS',
  Hungary: 'HUNGARY',
  Iceland: 'ICELAND',
  India: 'INDIA',
  Indonesia: 'INDONESIA',
  Iran: 'IRAN',
  Iraq: 'IRAQ',
  Ireland: 'IRELAND',
  Israel: 'ISRAEL',
  Italy: 'ITALY',
  Jamaica: 'JAMAICA',
  Japan: 'JAPAN',
  Jordan: 'JORDAN',
  Kazakhstan: 'KAZAKHSTAN',
  Kenya: 'KENYA',
  Kiribati: 'KIRIBATI',
  Kuwait: 'KUWAIT',
  Kyrgyzstan: 'KYRGYZSTAN',
  Laos: 'LAOS',
  Latvia: 'LATVIA',
  Lebanon: 'LEBANON',
  Lesotho: 'LESOTHO',
  Liberia: 'LIBERIA',
  Libya: 'LIBYA',
  Liechtenstein: 'LIECHTENSTEIN',
  Lithuania: 'LITHUANIA',
  Luxembourg: 'LUXEMBOURG',
  Madagascar: 'MADAGASCAR',
  Malawi: 'MALAWI',
  Malaysia: 'MALAYSIA',
  Maldives: 'MALDIVES',
  Mali: 'MALI',
  Malta: 'MALTA',
  MarshallIslands: 'MARSHALL_ISLANDS',
  Mauritania: 'MAURITANIA',
  Mauritius: 'MAURITIUS',
  Mexico: 'MEXICO',
  Micronesia: 'MICRONESIA',
  Moldova: 'MOLDOVA',
  Monaco: 'MONACO',
  Mongolia: 'MONGOLIA',
  Montenegro: 'MONTENEGRO',
  Morocco: 'MOROCCO',
  Mozambique: 'MOZAMBIQUE',
  Myanmar: 'MYANMAR',
  Namibia: 'NAMIBIA',
  Nauru: 'NAURU',
  Nepal: 'NEPAL',
  Netherlands: 'NETHERLANDS',
  NewZealand: 'NEW_ZEALAND',
  Nicaragua: 'NICARAGUA',
  Niger: 'NIGER',
  Nigeria: 'NIGERIA',
  NorthKorea: 'NORTH_KOREA',
  NorthMacedonia: 'NORTH_MACEDONIA',
  Norway: 'NORWAY',
  Oman: 'OMAN',
  Pakistan: 'PAKISTAN',
  Palau: 'PALAU',
  PalestineState: 'PALESTINE_STATE',
  Panama: 'PANAMA',
  PapuaNewGuinea: 'PAPUA_NEW_GUINEA',
  Paraguay: 'PARAGUAY',
  Peru: 'PERU',
  Philippines: 'PHILIPPINES',
  Poland: 'POLAND',
  Portugal: 'PORTUGAL',
  Qatar: 'QATAR',
  Romania: 'ROMANIA',
  Russia: 'RUSSIA',
  Rwanda: 'RWANDA',
  SaintKittsAndNevis: 'SAINT_KITTS_AND_NEVIS',
  SaintLucia: 'SAINT_LUCIA',
  SaintVincentAndTheGrenadines: 'SAINT_VINCENT_AND_THE_GRENADINES',
  Samoa: 'SAMOA',
  SanMarino: 'SAN_MARINO',
  SaoTomeAndPrincipe: 'SAO_TOME_AND_PRINCIPE',
  SaudiArabia: 'SAUDI_ARABIA',
  Senegal: 'SENEGAL',
  Serbia: 'SERBIA',
  Seychelles: 'SEYCHELLES',
  SierraLeone: 'SIERRA_LEONE',
  Singapore: 'SINGAPORE',
  Slovakia: 'SLOVAKIA',
  Slovenia: 'SLOVENIA',
  SolomonIslands: 'SOLOMON_ISLANDS',
  Somalia: 'SOMALIA',
  SouthAfrica: 'SOUTH_AFRICA',
  SouthKorea: 'SOUTH_KOREA',
  SouthSudan: 'SOUTH_SUDAN',
  Spain: 'SPAIN',
  SriLanka: 'SRI_LANKA',
  Sudan: 'SUDAN',
  Suriname: 'SURINAME',
  Sweden: 'SWEDEN',
  Switzerland: 'SWITZERLAND',
  Syria: 'SYRIA',
  Tajikistan: 'TAJIKISTAN',
  Tanzania: 'TANZANIA',
  Thailand: 'THAILAND',
  TimorLeste: 'TIMOR_LESTE',
  Togo: 'TOGO',
  Tonga: 'TONGA',
  TrinidadAndTobago: 'TRINIDAD_AND_TOBAGO',
  Tunisia: 'TUNISIA',
  Turkey: 'TURKEY',
  Turkmenistan: 'TURKMENISTAN',
  Tuvalu: 'TUVALU',
  Uganda: 'UGANDA',
  Ukraine: 'UKRAINE',
  UnitedArabEmirates: 'UNITED_ARAB_EMIRATES',
  UnitedKingdom: 'UNITED_KINGDOM',
  UnitedStatesOfAmerica: 'UNITED_STATES_OF_AMERICA',
  Uruguay: 'URUGUAY',
  Uzbekistan: 'UZBEKISTAN',
  Vanuatu: 'VANUATU',
  Venezuela: 'VENEZUELA',
  Vietnam: 'VIETNAM',
  Yemen: 'YEMEN',
  Zambia: 'ZAMBIA',
  Zimbabwe: 'ZIMBABWE'
} as const;

export type Country = typeof Country[keyof typeof Country];
export type InputChunk = {
  addressInput: AddressInput;
  companyInput: CompanyInput;
  jobInput: JobInput;
  jobSkillsInput: JobSkillsInput;
};

export type Job = {
  __typename?: 'Job';
  applicationContact: Scalars['String'];
  category: Category;
  company: Company;
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  jobSkills: Array<JobSkill>;
  jobType: JobType;
  language: LanguageType;
  title: Scalars['String'];
};

export type JobInput = {
  applicationContact: Scalars['String'];
  category: Category;
  description: Scalars['String'];
  jobType: JobType;
  language: LanguageType;
  title: Scalars['String'];
};

export type JobSkill = {
  __typename?: 'JobSkill';
  id: Scalars['ID'];
  jobId: Scalars['String'];
  skill: Skill;
};

export type JobSkillsInput = {
  skills?: InputMaybe<Scalars['String']>;
};

/** contract type of working */
export const JobType = {
  Contract: 'CONTRACT',
  FullTime: 'FULL_TIME'
} as const;

export type JobType = typeof JobType[keyof typeof JobType];
/** language to use in the job */
export const LanguageType = {
  Amharic: 'AMHARIC',
  Arabic: 'ARABIC',
  Basque: 'BASQUE',
  Bengali: 'BENGALI',
  Bulgarian: 'BULGARIAN',
  Catalan: 'CATALAN',
  Cherokee: 'CHEROKEE',
  Chinese: 'CHINESE',
  Croatian: 'CROATIAN',
  Czech: 'CZECH',
  Danish: 'DANISH',
  Dutch: 'DUTCH',
  English: 'ENGLISH',
  Estonian: 'ESTONIAN',
  Filipino: 'FILIPINO',
  Finnish: 'FINNISH',
  French: 'FRENCH',
  German: 'GERMAN',
  Greek: 'GREEK',
  Gujarati: 'GUJARATI',
  Hebrew: 'HEBREW',
  Hindi: 'HINDI',
  Hungarian: 'HUNGARIAN',
  Icelandic: 'ICELANDIC',
  Indonesian: 'INDONESIAN',
  Italian: 'ITALIAN',
  Japanese: 'JAPANESE',
  Kannada: 'KANNADA',
  Korean: 'KOREAN',
  Latvian: 'LATVIAN',
  Lithuanian: 'LITHUANIAN',
  Malay: 'MALAY',
  Malayalam: 'MALAYALAM',
  Marathi: 'MARATHI',
  Norwegian: 'NORWEGIAN',
  Polish: 'POLISH',
  Portuguese: 'PORTUGUESE',
  Romanian: 'ROMANIAN',
  Russian: 'RUSSIAN',
  Serbian: 'SERBIAN',
  Slovak: 'SLOVAK',
  Slovenian: 'SLOVENIAN',
  Spanish: 'SPANISH',
  Swahili: 'SWAHILI',
  Swedish: 'SWEDISH',
  Tamil: 'TAMIL',
  Telugu: 'TELUGU',
  Thai: 'THAI',
  Turkish: 'TURKISH',
  Ukrainian: 'UKRAINIAN',
  Urdu: 'URDU',
  Vietnamese: 'VIETNAMESE',
  Welsh: 'WELSH'
} as const;

export type LanguageType = typeof LanguageType[keyof typeof LanguageType];
export type ProfileSettingInput = {
  isVisible: Scalars['Boolean'];
  useProfileEmail: Scalars['Boolean'];
  useProfileImage: Scalars['Boolean'];
};

export type Query = {
  __typename?: 'Query';
  allJobs: Array<Job>;
  allUsers: Array<User>;
  companyAddress?: Maybe<CompanyAddress>;
  searchJobs: Array<Job>;
  searchUsers: Array<User>;
};


export type QueryAllJobsArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryAllUsersArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryCompanyAddressArgs = {
  id: Scalars['ID'];
};


export type QuerySearchJobsArgs = {
  id?: InputMaybe<Scalars['ID']>;
  query?: InputMaybe<Array<Scalars['String']>>;
};


export type QuerySearchUsersArgs = {
  id?: InputMaybe<Scalars['ID']>;
  query?: InputMaybe<Array<Scalars['String']>>;
};

/** all skills */
export const Skill = {
  Access: 'ACCESS',
  AngularJs: 'ANGULAR_JS',
  Aws: 'AWS',
  Bootstrap: 'BOOTSTRAP',
  CakePhp: 'CAKE_PHP',
  ChakraUi: 'CHAKRA_UI',
  Css: 'CSS',
  Django: 'DJANGO',
  Docker: 'DOCKER',
  Excel: 'EXCEL',
  Figma: 'FIGMA',
  Flask: 'FLASK',
  Flutter: 'FLUTTER',
  FuelPhp: 'FUEL_PHP',
  Gcp: 'GCP',
  Go: 'GO',
  Html: 'HTML',
  Illustrator: 'ILLUSTRATOR',
  Java: 'JAVA',
  Javascript: 'JAVASCRIPT',
  Laravel: 'LARAVEL',
  Mongodb: 'MONGODB',
  Mysql: 'MYSQL',
  NextJs: 'NEXT_JS',
  NodeJs: 'NODE_JS',
  Photoshop: 'PHOTOSHOP',
  Php: 'PHP',
  Postgres: 'POSTGRES',
  Python: 'PYTHON',
  React: 'REACT',
  Ruby: 'RUBY',
  RubyOnRails: 'RUBY_ON_RAILS',
  Rust: 'RUST',
  Sinatra: 'SINATRA',
  Spring: 'SPRING',
  Swift: 'SWIFT',
  TailwindCss: 'TAILWIND_CSS',
  Typescript: 'TYPESCRIPT',
  Unity: 'UNITY',
  Vue: 'VUE',
  Webpacker: 'WEBPACKER',
  Word: 'WORD',
  Xd: 'XD'
} as const;

export type Skill = typeof Skill[keyof typeof Skill];
export type User = {
  __typename?: 'User';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  profile?: Maybe<UserProfile>;
  updatedAt: Scalars['DateTime'];
};

export type UserJobCategory = {
  __typename?: 'UserJobCategory';
  category: Category;
  id: Scalars['ID'];
  userProfileId: Scalars['String'];
};

export type UserProfile = {
  __typename?: 'UserProfile';
  accountId?: Maybe<Scalars['String']>;
  categories: Array<UserJobCategory>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  jobType?: Maybe<JobType>;
  language?: Maybe<LanguageType>;
  salesCopy?: Maybe<Scalars['String']>;
  skills: Array<UserSkill>;
  userId: Scalars['String'];
};

export type UserProfileInput = {
  categories: Category;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  jobType: JobType;
  language?: InputMaybe<LanguageType>;
  lastName?: InputMaybe<Scalars['String']>;
  salesCopy?: InputMaybe<Scalars['String']>;
  skills: JobSkillsInput;
};

export type UserSkill = {
  __typename?: 'UserSkill';
  id: Scalars['ID'];
  skill: Skill;
  userProfileId: Scalars['String'];
};

export type AddressFieldsFragment = { __typename?: 'Address', id: string, zipCode: string, country: string, state: string, street: string, building?: string | null };

export type CompanyFieldsFragment = { __typename?: 'Company', id: string, name: string, websiteUrl: string, logo: string, description: string, jobs: Array<{ __typename?: 'Job', id: string, title: string, category: Category, jobType: JobType, applicationContact: string, description: string, createdAt: string, companyId: string, language: LanguageType } | null>, companyAddress?: { __typename?: 'CompanyAddress', id: string, companyId: string, addressId: string } | null };

export type CompanyAddressFieldsFragment = { __typename?: 'CompanyAddress', id: string, companyId: string, addressId: string };

export type AllJobsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;


export type AllJobsQuery = { __typename?: 'Query', allJobs: Array<{ __typename?: 'Job', id: string, title: string, category: Category, jobType: JobType, applicationContact: string, description: string, createdAt: string, companyId: string, language: LanguageType, jobSkills: Array<{ __typename?: 'JobSkill', id: string, jobId: string, skill: Skill }>, company: { __typename?: 'Company', id: string, name: string, websiteUrl: string, logo: string, description: string, jobs: Array<{ __typename?: 'Job', id: string, title: string, category: Category, jobType: JobType, applicationContact: string, description: string, createdAt: string, companyId: string, language: LanguageType } | null>, companyAddress?: { __typename?: 'CompanyAddress', id: string, companyId: string, addressId: string } | null } }> };

export type SearchJobsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  query?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type SearchJobsQuery = { __typename?: 'Query', searchJobs: Array<{ __typename?: 'Job', id: string, title: string, category: Category, jobType: JobType, applicationContact: string, description: string, createdAt: string, companyId: string, language: LanguageType, jobSkills: Array<{ __typename?: 'JobSkill', id: string, jobId: string, skill: Skill }>, company: { __typename?: 'Company', id: string, name: string, websiteUrl: string, logo: string, description: string, jobs: Array<{ __typename?: 'Job', id: string, title: string, category: Category, jobType: JobType, applicationContact: string, description: string, createdAt: string, companyId: string, language: LanguageType } | null>, companyAddress?: { __typename?: 'CompanyAddress', id: string, companyId: string, addressId: string } | null } }> };

export type JobFieldsFragment = { __typename?: 'Job', id: string, title: string, category: Category, jobType: JobType, applicationContact: string, description: string, createdAt: string, companyId: string, language: LanguageType };

export type JobSkillFieldsFragment = { __typename?: 'JobSkill', id: string, jobId: string, skill: Skill };

export type AllUsersQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;


export type AllUsersQuery = { __typename?: 'Query', allUsers: Array<{ __typename?: 'User', id: string, name?: string | null, image?: string | null, createdAt: string, updatedAt: string, profile?: { __typename?: 'UserProfile', id: string, userId: string, accountId?: string | null, language?: LanguageType | null, image?: string | null, salesCopy?: string | null, jobType?: JobType | null, skills: Array<{ __typename?: 'UserSkill', id: string, userProfileId: string, skill: Skill }>, categories: Array<{ __typename?: 'UserJobCategory', id: string, userProfileId: string, category: Category }> } | null }> };

export type SearchUsersQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  query?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type SearchUsersQuery = { __typename?: 'Query', searchUsers: Array<{ __typename?: 'User', id: string, name?: string | null, image?: string | null, createdAt: string, updatedAt: string, profile?: { __typename?: 'UserProfile', id: string, userId: string, accountId?: string | null, language?: LanguageType | null, image?: string | null, salesCopy?: string | null, jobType?: JobType | null, skills: Array<{ __typename?: 'UserSkill', id: string, userProfileId: string, skill: Skill }>, categories: Array<{ __typename?: 'UserJobCategory', id: string, userProfileId: string, category: Category }> } | null }> };

export type UserFieldsFragment = { __typename?: 'User', id: string, name?: string | null, image?: string | null, createdAt: string, updatedAt: string };

export type UserJobCategoryFieldsFragment = { __typename?: 'UserJobCategory', id: string, userProfileId: string, category: Category };

export type UserProfileFieldsFragment = { __typename?: 'UserProfile', id: string, userId: string, accountId?: string | null, language?: LanguageType | null, image?: string | null, salesCopy?: string | null, jobType?: JobType | null };

export type UserSkillFieldsFragment = { __typename?: 'UserSkill', id: string, userProfileId: string, skill: Skill };

export const AddressFieldsFragmentDoc = gql`
    fragment AddressFields on Address {
  id
  zipCode
  country
  state
  street
  building
}
    `;
export const JobFieldsFragmentDoc = gql`
    fragment JobFields on Job {
  id
  title
  category
  jobType
  applicationContact
  description
  createdAt
  companyId
  language
}
    `;
export const CompanyAddressFieldsFragmentDoc = gql`
    fragment CompanyAddressFields on CompanyAddress {
  id
  companyId
  addressId
}
    `;
export const CompanyFieldsFragmentDoc = gql`
    fragment CompanyFields on Company {
  id
  name
  websiteUrl
  logo
  description
  jobs {
    ...JobFields
  }
  companyAddress {
    ...CompanyAddressFields
  }
}
    ${JobFieldsFragmentDoc}
${CompanyAddressFieldsFragmentDoc}`;
export const JobSkillFieldsFragmentDoc = gql`
    fragment JobSkillFields on JobSkill {
  id
  jobId
  skill
}
    `;
export const UserFieldsFragmentDoc = gql`
    fragment UserFields on User {
  id
  name
  image
  createdAt
  updatedAt
}
    `;
export const UserJobCategoryFieldsFragmentDoc = gql`
    fragment UserJobCategoryFields on UserJobCategory {
  id
  userProfileId
  category
}
    `;
export const UserProfileFieldsFragmentDoc = gql`
    fragment UserProfileFields on UserProfile {
  id
  userId
  accountId
  language
  image
  salesCopy
  jobType
}
    `;
export const UserSkillFieldsFragmentDoc = gql`
    fragment UserSkillFields on UserSkill {
  id
  userProfileId
  skill
}
    `;
export const AllJobsDocument = gql`
    query AllJobs($id: ID) {
  allJobs(id: $id) {
    ...JobFields
    jobSkills {
      ...JobSkillFields
    }
    company {
      ...CompanyFields
    }
  }
}
    ${JobFieldsFragmentDoc}
${JobSkillFieldsFragmentDoc}
${CompanyFieldsFragmentDoc}`;

/**
 * __useAllJobsQuery__
 *
 * To run a query within a React component, call `useAllJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllJobsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAllJobsQuery(baseOptions?: Apollo.QueryHookOptions<AllJobsQuery, AllJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllJobsQuery, AllJobsQueryVariables>(AllJobsDocument, options);
      }
export function useAllJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllJobsQuery, AllJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllJobsQuery, AllJobsQueryVariables>(AllJobsDocument, options);
        }
export type AllJobsQueryHookResult = ReturnType<typeof useAllJobsQuery>;
export type AllJobsLazyQueryHookResult = ReturnType<typeof useAllJobsLazyQuery>;
export type AllJobsQueryResult = Apollo.QueryResult<AllJobsQuery, AllJobsQueryVariables>;
export const SearchJobsDocument = gql`
    query SearchJobs($id: ID, $query: [String!]) {
  searchJobs(id: $id, query: $query) {
    ...JobFields
    jobSkills {
      ...JobSkillFields
    }
    company {
      ...CompanyFields
    }
  }
}
    ${JobFieldsFragmentDoc}
${JobSkillFieldsFragmentDoc}
${CompanyFieldsFragmentDoc}`;

/**
 * __useSearchJobsQuery__
 *
 * To run a query within a React component, call `useSearchJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchJobsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchJobsQuery(baseOptions?: Apollo.QueryHookOptions<SearchJobsQuery, SearchJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchJobsQuery, SearchJobsQueryVariables>(SearchJobsDocument, options);
      }
export function useSearchJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchJobsQuery, SearchJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchJobsQuery, SearchJobsQueryVariables>(SearchJobsDocument, options);
        }
export type SearchJobsQueryHookResult = ReturnType<typeof useSearchJobsQuery>;
export type SearchJobsLazyQueryHookResult = ReturnType<typeof useSearchJobsLazyQuery>;
export type SearchJobsQueryResult = Apollo.QueryResult<SearchJobsQuery, SearchJobsQueryVariables>;
export const AllUsersDocument = gql`
    query AllUsers($id: ID) {
  allUsers(id: $id) {
    ...UserFields
    profile {
      ...UserProfileFields
      skills {
        ...UserSkillFields
      }
      categories {
        ...UserJobCategoryFields
      }
    }
  }
}
    ${UserFieldsFragmentDoc}
${UserProfileFieldsFragmentDoc}
${UserSkillFieldsFragmentDoc}
${UserJobCategoryFieldsFragmentDoc}`;

/**
 * __useAllUsersQuery__
 *
 * To run a query within a React component, call `useAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUsersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAllUsersQuery(baseOptions?: Apollo.QueryHookOptions<AllUsersQuery, AllUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllUsersQuery, AllUsersQueryVariables>(AllUsersDocument, options);
      }
export function useAllUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllUsersQuery, AllUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllUsersQuery, AllUsersQueryVariables>(AllUsersDocument, options);
        }
export type AllUsersQueryHookResult = ReturnType<typeof useAllUsersQuery>;
export type AllUsersLazyQueryHookResult = ReturnType<typeof useAllUsersLazyQuery>;
export type AllUsersQueryResult = Apollo.QueryResult<AllUsersQuery, AllUsersQueryVariables>;
export const SearchUsersDocument = gql`
    query SearchUsers($id: ID, $query: [String!]) {
  searchUsers(id: $id, query: $query) {
    ...UserFields
    profile {
      ...UserProfileFields
      skills {
        ...UserSkillFields
      }
      categories {
        ...UserJobCategoryFields
      }
    }
  }
}
    ${UserFieldsFragmentDoc}
${UserProfileFieldsFragmentDoc}
${UserSkillFieldsFragmentDoc}
${UserJobCategoryFieldsFragmentDoc}`;

/**
 * __useSearchUsersQuery__
 *
 * To run a query within a React component, call `useSearchUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUsersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchUsersQuery(baseOptions?: Apollo.QueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, options);
      }
export function useSearchUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, options);
        }
export type SearchUsersQueryHookResult = ReturnType<typeof useSearchUsersQuery>;
export type SearchUsersLazyQueryHookResult = ReturnType<typeof useSearchUsersLazyQuery>;
export type SearchUsersQueryResult = Apollo.QueryResult<SearchUsersQuery, SearchUsersQueryVariables>;