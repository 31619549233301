import { VStack, HStack, Box, Badge } from "@chakra-ui/react";

import { FC } from "react";
import type { AllJobsQuery } from "@codegen";

const JobBadge: FC<{ job: AllJobsQuery["allJobs"][number] }> = ({ job }) => {
  const fontSize = { base: "xs", md: "md" };
  return (
    <Box>
      <HStack width="full" display={{ base: "none", md: "block" }}>
        <Badge colorScheme="red" variant="outline" fontSize={fontSize}>
          {job.jobType}
        </Badge>
        <Badge colorScheme="red" variant="outline" fontSize={fontSize}>
          {job.category}
        </Badge>
        {job.jobSkills.map((skill) => {
          return (
            <Badge
              key={skill.id}
              colorScheme="green"
              variant="outline"
              fontSize={fontSize}
            >
              {skill.skill}
            </Badge>
          );
        })}
      </HStack>
      <VStack display={{ base: "flex", md: "none" }}>
        <HStack width="full">
          <Badge colorScheme="red" variant="outline" fontSize={fontSize}>
            {job.jobType}
          </Badge>
          <Badge colorScheme="red" variant="outline" fontSize={fontSize}>
            {job.category}
          </Badge>
        </HStack>
        <HStack width="full">
          {job.jobSkills.map((skill) => {
            return (
              <Badge
                key={skill.id}
                colorScheme="green"
                variant="outline"
                fontSize={fontSize}
              >
                {skill.skill}
              </Badge>
            );
          })}
        </HStack>
      </VStack>
    </Box>
  );
};

export { JobBadge };
