import { Skill } from "@prisma/client";

const imagePath = (skill: string) => {
  return `/skills/${skill}.svg`;
};

const skillImageUrls = Object.values(Skill).reduce((prev, current, index) => {
  prev[current] = imagePath(current.toLowerCase());
  return prev;
}, {} as { [key in Skill]: string });

const getSkillImage = (skill: Skill) => {
  return skillImageUrls[skill];
};

export { getSkillImage };
