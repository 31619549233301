import { createContext } from "react";
import { Dispatch, SetStateAction } from "react";

export type LabelType = {
  label: string;
  value: string;
  group: string;
};

export type SearchContexType = {
  searchInput: LabelType[];
  setSearchInput: Dispatch<SetStateAction<LabelType[]>>;
};

export const SearchContext = createContext<SearchContexType>(
  {} as SearchContexType
);
