import Head from "next/head";
import { useRouter } from "next/router";
import { FC } from "react";
import { Constants } from "../constants";

const SEOLayout: FC<{
  title?: string;
  description: string;
  image: string;
  imageWidth?: number;
  imageHeight?: number;
  children: React.ReactNode;
}> = ({
  title = Constants.APP_NAME,
  description,
  image,
  imageWidth = 640,
  imageHeight = 1280,
  children,
}) => {
  const router = useRouter();
  const pageUrl =
    (process.env.NEXT_PUBLIC_HOST_URL as string) + router.pathname;

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="viewport" content="width=device-width,initial-scale=1.0" />
        <meta name="description" content={description} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:title" content={title} />
        <meta property="og:site_name" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={image} />
        <meta property="og:image:width" content={String(imageWidth)} />
        <meta property="og:image:height" content={String(imageHeight)} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:descriptio" content={description} />
        <meta name="twitter:image" content={image} />
        <link rel="canonical" href={pageUrl} />
      </Head>
      {children}
    </>
  );
};

export { SEOLayout };
