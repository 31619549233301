import {
  VStack,
  Text,
  HStack,
  Avatar,
  Box,
  Link,
  useBreakpoint,
  Stack,
} from "@chakra-ui/react";

import { format } from "date-fns";
import NextLink from "next/link";
import { FC } from "react";
import { Constants } from "../../../constants";
import { AddToFavoriteButton } from "./AddToFavoriteButton";
import type { AllJobsQuery } from "@codegen";
import { JobBadge } from "@components/pages/index/JobBadge";

const JobLink: FC<{
  job: AllJobsQuery["allJobs"][0];
}> = ({ job }) => {
  const bp = useBreakpoint();
  const textLength = bp === "base" ? 64 : 64;

  return (
    <>
      <Stack
        p="4"
        background="white"
        width="full"
        spacing={{ base: 2, md: 8 }}
        direction={{ base: "column", md: "row" }}
        justifyContent="center"
        alignItems="center"
      >
        {(bp === "base" || bp === "sm") && (
          <HStack justifyContent="space-between" width="full">
            <JobBadge job={job} />
            <AddToFavoriteButton<AllJobsQuery["allJobs"][number]>
              item={job}
              display={{ base: "block", md: "none" }}
              keyName={Constants.FAVORITE_JOBS_STORAGE_KEY}
            />
          </HStack>
        )}
        <NextLink href={`/jobs/${job.id}`} passHref>
          <Link width="full">
            <HStack width="full" spacing={{ base: 0, md: "flex" }}>
              <Avatar
                name={job.company.name}
                src={job.company.logo}
                mr="2"
                border="2px"
                color="white"
                size="lg"
                display={{ base: "none", md: "block" }}
              />
              <VStack alignItems="flex-start" spacing={1} flex={1}>
                <HStack
                  width="full"
                  justifyContent={{ base: "space-between", md: "flex-start" }}
                >
                  <HStack>
                    {(bp === "base" || bp === "sm") && (
                      <Avatar
                        name={job.company.name}
                        src={job.company.logo}
                        mr="2"
                        border="2px"
                        color="white"
                        size="sm"
                        display={{ base: "block", md: "none" }}
                      />
                    )}
                    <Text>&#x1f3e2;&nbsp;{job.company.name}</Text>
                  </HStack>
                  <Text
                    fontWeight="bold"
                    display={{ base: "block", md: "none" }}
                  >
                    {format(new Date(job.createdAt), "yyyy/M/d")}
                  </Text>
                </HStack>
                <Box display={{ base: "none", md: "block" }}>
                  <JobBadge job={job} />
                </Box>
                <Text fontWeight="bold" fontSize="xl" mt={0}>
                  {job.title.length >= textLength
                    ? job.title.slice(0, textLength - 3).padEnd(textLength, ".")
                    : job.title}
                </Text>
              </VStack>
              <Box display={{ base: "none", md: "block" }}>
                <Text fontWeight="bold">
                  {format(new Date(job.createdAt), "yyyy/M/d")}
                </Text>
              </Box>
            </HStack>
          </Link>
        </NextLink>
        <AddToFavoriteButton<AllJobsQuery["allJobs"][number]>
          item={job}
          display={{ base: "none", md: "block" }}
          keyName={Constants.FAVORITE_JOBS_STORAGE_KEY}
        />
      </Stack>
    </>
  );
};

export { JobLink };
