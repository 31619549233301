import { VStack, HStack, Switch } from "@chakra-ui/react";
import dynamic from "next/dynamic";
import { useContext, useState } from "react";
import type { ChangeEvent } from "react";
import { AllUsers } from "./AllUsers";
import { FilteredUsers } from "./FilteredUsers";
import { SearchContext } from "@common/client/store";

const SearchForm = dynamic(
  () => import("./SearchForm").then((mod) => mod.SearchForm),
  { ssr: false }
);

const FavoriteUsers = dynamic(
  () => import("./FavoriteUsers").then((mod) => mod.FavoriteUsers),
  { ssr: false }
);

const RecruiterTab = () => {
  const { searchInput } = useContext(SearchContext);

  const [lookingFavorite, setLookingFavorite] = useState<boolean>(false);

  const handleSwitch = (event: ChangeEvent<HTMLInputElement>): void => {
    const isChecked = event.currentTarget.checked;
    setLookingFavorite(isChecked);
  };
  return (
    <VStack width="full" alignItems="flex-start" mt="6" spacing="8">
      <HStack
        width="full"
        spacing="6"
        justifyContent="center"
        alignItems="center"
      >
        <Switch
          variant="clipIcon"
          height="full"
          colorScheme="red"
          size="lg"
          onChange={handleSwitch}
        />
        <SearchForm isDisabled={lookingFavorite} />
      </HStack>
      {lookingFavorite && <FavoriteUsers />}
      {!lookingFavorite && !!searchInput.length && <FilteredUsers />}
      {!lookingFavorite && !searchInput.length && <AllUsers />}
    </VStack>
  );
};

export { RecruiterTab };
