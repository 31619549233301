import {
  Stack,
  HStack,
  Avatar,
  VStack,
  Link,
  Box,
  Text,
  useBreakpoint,
} from "@chakra-ui/react";
import { format } from "date-fns";
import NextLink from "next/link";
import type { FC } from "react";
import { Constants } from "../../../constants";
import { AddToFavoriteButton } from "./AddToFavoriteButton";
import { UserSkillBadge } from "./UserSkillBadge";
import type { AllUsersQuery } from "@codegen";
import { getRandomAnimal } from "@common/client";

const UserLink: FC<{ user: AllUsersQuery["allUsers"][number] }> = ({
  user,
}) => {
  const bp = useBreakpoint();
  const textLength = bp === "base" ? 64 : 64;
  const salesCopy = user.profile?.salesCopy || "";

  return (
    <>
      <Stack
        p="4"
        background="white"
        width="full"
        spacing={{ base: 2, md: 8 }}
        direction={{ base: "column", md: "row" }}
        justifyContent="center"
        alignItems="center"
      >
        {(bp === "base" || bp === "sm") && (
          <HStack justifyContent="space-between" width="full">
            {(bp === "base" || bp === "sm") && (
              <Avatar
                name={user.name as string}
                src={user.image || `/animals/${getRandomAnimal()}.svg`}
                mr="2"
                border="2px"
                color="white"
                size="md"
                display={{ base: "block", md: "none" }}
              />
            )}
            <UserSkillBadge user={user} />
            <AddToFavoriteButton<AllUsersQuery["allUsers"][number]>
              item={user}
              display={{ base: "block", md: "none" }}
              keyName={Constants.FAVORITE_USERS_STORAGE_KEY}
            />
          </HStack>
        )}
        <NextLink
          href={`https://github.com/${user.profile?.accountId}`}
          passHref
        >
          <Link width="full">
            <HStack width="full" spacing={{ base: 0, md: "flex" }}>
              <Avatar
                name={user.name as string}
                src={user.image || `/animals/${getRandomAnimal()}.svg`}
                mr="2"
                border="2px"
                color="white"
                size="lg"
                display={{ base: "none", md: "block" }}
              />
              <VStack alignItems="flex-start" spacing={1} flex={1}>
                <HStack
                  width="full"
                  justifyContent={{ base: "space-between", md: "flex-start" }}
                >
                  <Text
                    fontWeight="bold"
                    fontSize="xl"
                    mt={0}
                    display={{ base: "block", md: "none" }}
                  >
                    {salesCopy.length >= textLength
                      ? salesCopy
                          .slice(0, textLength - 3)
                          .padEnd(textLength, ".")
                      : salesCopy}
                  </Text>
                  <Text
                    fontWeight="bold"
                    display={{ base: "block", md: "none" }}
                  >
                    {format(new Date(user.createdAt), "yyyy/M/d")}
                  </Text>
                </HStack>
                <Box display={{ base: "none", md: "block" }}>
                  <UserSkillBadge user={user} />
                </Box>
                <Text
                  fontWeight="bold"
                  fontSize="xl"
                  mt={0}
                  display={{ base: "none", md: "block" }}
                >
                  {salesCopy.length >= textLength
                    ? salesCopy.slice(0, textLength - 3).padEnd(textLength, ".")
                    : salesCopy}
                </Text>
              </VStack>
              <Box display={{ base: "none", md: "block" }}>
                <Text fontWeight="bold">
                  {format(new Date(user.createdAt), "yyyy/M/d")}
                </Text>
              </Box>
            </HStack>
          </Link>
        </NextLink>
        <AddToFavoriteButton<AllUsersQuery["allUsers"][number]>
          item={user}
          display={{ base: "none", md: "block" }}
          keyName={Constants.FAVORITE_USERS_STORAGE_KEY}
        />
      </Stack>
    </>
  );
};

export { UserLink };
